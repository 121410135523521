import axiosInstance from '../axiosInstance'

export const basicStep = async (data) => {
    const { name, email, company_name, industry } = data;
    try {
        const resp = await axiosInstance.post("/api/handle/basic", { name, company_name, email, industry });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const teamExpenses = async (data) => {
    const { teams_month_cost, identifier } = data;
    try {
        const resp = await axiosInstance.post(`/api/handle/team-expenses/${identifier}`, { team_expenses: teams_month_cost });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const investmentExpenses = async (data) => {
    const { month_invest, identifier } = data;
    try {
        const resp = await axiosInstance.post(`/api/handle/investment-expenses/${identifier}`, { investment_expenses: month_invest });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const businessExpenses = async (data) => {
    const { month_expenses, identifier } = data;
    try {
        const resp = await axiosInstance.post(`/api/handle/business-expenses/${identifier}`, { business_expenses: month_expenses });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const personalExpenses = async (data) => {
    const { month_personal_expenses, identifier } = data;
    try {
        const resp = await axiosInstance.post(`/api/handle/personal-expenses/${identifier}`, { personal_expenses: month_personal_expenses });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const feelings = async (data) => {
    const { feeling, identifier } = data;
    try {
        const resp = await axiosInstance.post(`/api/handle/feeling/${identifier}`, { feeling });
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const showRevenue = async (data) => {
    const { identifier } = data;
    try {
        const resp = await axiosInstance.get(`/api/revenue/${identifier}`);
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const showDetails = async (data) => {
    const { identifier } = data;
    try {
        const resp = await axiosInstance.get(`/api/show/${identifier}`);
        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const finalStep = async (data) => {
    const { identifier } = data;

    try {
        const resp = await axiosInstance.post(`/api/handle/submit/${identifier}`);

        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}

export const notify = async (data) => {
    const { identifier } = data;

    try {
        const resp = await axiosInstance.post(`/api/handle/notify/${identifier}`);

        return resp;
    } catch (err) {
        return { error: err?.response?.data?.errors?.length ? err?.response?.data?.errors : err?.response?.data?.message || err.message };
    }
}